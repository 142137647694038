.custom-card-title {
  font-size: 30px;
  color: #F26532;
  font-weight: 600;
}

.custom-card-textt {
  font-size: 20px;
  font-weight: 500;
}
