.not-found-container {
  position: relative;
  background-color: var(--LIGHT_GREY);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--WHITE);
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--MODALBG);
}

.content {
  position: relative;
  z-index: 1;
}

.display-1 {
  font-family: "Montserrat", sans-serif;
  font-size: 10rem;
  animation: pulse 1.5s infinite;
  letter-spacing: 15px;
  color: var(--WHITE_FADE);
}

.fs-3 {
  font-family: "Poppins", sans-serif;
  font-size: 1.75rem;
  color: var(--WHITE_FADE);
}

.lead {
  font-size: 1.25rem;
  margin-top: 20px;
  color: var(--LIGHT_GREY);
}

.btn-blue {
  background-color: var(--BLUE);
  border-color: var(--DARK_BLUE);
  color: var(--WHITE);
  padding: 12px 30px;
  font-size: 1.2rem;
  box-shadow: var(--BOXSHADOW);
}

.btn-blue:hover {
  background-color: var(--DARK_BLUE);
  border-color: var(--DARK_BLUE);
  color: var(--WHITE);
}

.text-red {
  color: var(--RED);
}

/* Pulse animation for the 404 text */
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}
