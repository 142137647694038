.input {
  height: 40px;
}

.label {
  margin: 0;
  font-weight: 500;
  white-space: nowrap;
}

.error {
  color: var(--RED);
  font-size: 12px;
}
