

  .btn-orange {
    background-color: #ff6600;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-orange:hover {
    background-color: #e65500;
  }
  
  .btn-outline-primary {
    border-color: #007bff;
    color: #007bff;
  }
  
  .btn-outline-primary:hover {
    background-color: #007bff;
    color: white;
  }
  
  .text-center span {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  .leave-section {
    padding: 10px 15px; /* Add padding around each section */

  }
  
  .leave-title {
    font-size: 20px;
    color: #464255; /* Text color for the titles */
  }
  
  .leave-count {
    font-size: 30px;
    font-weight: 600; /* Make the count stand out */
    color: #464255; /* Optional blue color for the counts */
  }



  .row.LeaveDetailsrow {
    padding: 30px 0 0;
}

.row.LeaveDetailsrow .leave-section {
    padding: 20px 0px;
    text-align: center;
}


.row.employeeBottom .col-4 {
    width: 30%;
}

.row.employeeBottom .col-3 {
    width: 28%;
}

.row.employeeBottom .col-5 {
    width: 42%;
}

.row.employeeBottom .card{
  height: 100%;
}


.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 9px !important;
}

.rs-picker-toggle-wrapper{
  width: 100% !important;
}

.form-outline {
  position: relative !important;
}



 .rs-picker-menu.rs-picker-select-menu {
  padding-top: 6px;
  right: 40px !important;
}

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input{
  min-width: 100% !important;
}



@media screen and (max-width: 1400px) {


.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 5px !important;
}

}