.card-parent :hover {
  /* scale: 1.15; */
}
.graph-content {
  /* height: 5rem; */
}
/* .container {
  width: 50rem;
  height: 30rem;
} */
.chart-bar {
  /* margin-left: 3rem; */
  /* margin: 0 0 0 2.5rem; */
}
.upper-row {
  /* margin-bottom: 1rem; */
}
.chart-bar--child {
  overflow-x: hidden;
}
.chartbar {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 330px;
}
.chart-bar--child > div:nth-child(1) {
  /* margin: 0 0 0 3rem; */
  overflow-x: hidden;
  transform: scaleY(1);
  width: 300px;
  /* scale: 1.3; */
}
/* .pie-chart--child {
  width: 10rem;
} */

.card-border {
  border-color: #6289ec !important;
  margin-bottom: 1rem;
  min-height: 95%;
  max-width: 100%;
  min-width: 100%;
}
.card_date {
  display: flex;
  font-size: calc(9.5vw + 1vh);
  justify-content: center;
  color: var(--BLUE_LIGHT);
}
.chart-bar {
  margin-left: 1.5rem;
  margin-right: 1.3rem;
  width: 60% !important;
  overflow: hidden;
}

.pie-chart--child {
  scale: 1.3;
  /* overflow: hidden; */
}
.pie-chart {
  /* margin: 0 2rem 0 1rem; */
  overflow: hidden;
  padding: 0;
}
.pie-chart :hover {
  pointer-events: none;
}

.google-visualization-tooltip text {
  fill: #757575; /* Change text color if needed */
  transform: translateY(20px); /* Adjust vertical position as needed */
}

svg > g:nth-child(4) {
  width: 13rem;
}
svg > g:nth-child(2) > rect:nth-child(3) {
  transform: translate(-35rem, 15.5rem);
}
svg > g:nth-child(2) > text:nth-child(4) {
  transform: translate(-35rem, 15.25rem);
}
svg > g:nth-child(2) > rect:nth-child(5) {
  transform: translate(-28.2rem, 13.2rem);
}
svg > g:nth-child(2) > text:nth-child(6) {
  transform: translate(-28.2rem, 13rem);
}
svg > g:nth-child(2) > rect:nth-child(7) {
  transform: translate(-20.5rem, 11rem);
}
svg > g:nth-child(2) > text:nth-child(8) {
  transform: translate(-20.5rem, 10.8rem);
}

svg > g:nth-child(6) > text:nth-child(7) {
  transform: translate(-299px, -3px);
  display: none;
}

svg > g:nth-child(2) > text:nth-child(2) {
  transform: translate(0, -2rem);
}
svg[aria-label="A chart."] > g:nth-child(4) {
  transform: translate(50px, 180px);
}
svg[aria-label="A chart."] > g:nth-child(4) > g:nth-child(2) {
  transform: translate(-16rem, 3.3rem);
  font-size: 1rem;
  scale: 0.8;
}
svg[aria-label="A chart."] > g:nth-child(4) > g:nth-child(3) {
  transform: translate(-13.5rem, 2.1rem);
  font-size: 1rem;
  scale: 0.8;
}
svg[aria-label="A chart."] > g:nth-child(4) > g:nth-child(4) {
  transform: translate(-8.5rem, 0.9rem);
  scale: 0.8;
}
svg[aria-label="A chart."] > g:nth-child(4) > g:nth-child(5) {
  transform: translate(-5.3rem, -0.3rem);
  font-size: 1rem;
  scale: 0.8;
}
svg[aria-label="A chart."] > g:nth-child(3) > text:nth-child(1) {
  transform: translate(1rem, 2rem);
  font-size: 1rem;
  scale: 0.8;
}
svg[aria-label="A chart."] > g:nth-child(3) > rect:nth-child(2) {
  transform: translate(3rem, 1rem);
  font-family: sans-serif;
  font-weight: 500;
  color: #000;
  /* padding-top: 2rem; */
}
div[aria-label="A chart."] {
  pointer-events: none !important;
}
svg > g:nth-child(2) {
  overflow: hidden;
  width: 50rem;
  padding: 5rem;
  /* padding-right: 50rem; */
}
