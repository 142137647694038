.unauthorized-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--GRAY_LIGHT); /* Light gray background color */
}

.unauthorized-content {
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px var(--BLACK);
  background-color: var(--WHITE); /* White background color */
}

h1 {
  color: var(--ORRENGE); /* Attractive red color */
}

p {
  color: var(--GRAY_DARK_SHADOW_BACKGROUND); /* Dark gray text color */
}
