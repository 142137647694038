.drop-down {
  width: auto;
}
.drp-dwn--selectPro,
.drp-dwn--selectYear,
.drp-dwn--selectMonth,
.drp-dwn--selectDep {
  width: 215px;
  width: 150px;
  height: 40px;
  color: #827b7b;
  padding: 5px;
  text-align: start;
  border-right: none;
  background-color: transparent;
  border-color: #827b7b99;
  color: #625757;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.8px;
}
.drp-dwn--selectPro {
  width: 476px;
}

.drp-dwn--selectDep {
  width: 153px;
}
.drp-dwn--selectYear {
  width: 119px;
}

.drp-dwn--selectMonth {
  width: 215px;
}

.drp-dwn--selectBtn {
  width: 119px;
  width: 150px;
  height: 40px;
  border-color: #827b7b99;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.8px;
}
