.loginMaindiv {
  background: var(--WHITE);
  padding: 10px 50px;
}

.mainlogininputbox {
  background: var(--WHITE);
  padding: 50px 40px;
  margin: 65px;
  position: relative;
}

.squareBoxDots {
  position: absolute;
  top: -33px;
  left: -33px;
}

.squareBoxDotsright {
  position: absolute;
  bottom: -30px;
  right: -30px;
}

.squareBoxDots img,
.squareBoxDotsright img {
  height: 70px;
  width: 70px;
}
