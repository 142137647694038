.search-filter {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  /* font-size: 1px; */
  background-color: white;
  background-image: url("../../images/searchIcon.svg");
  background-position: 97% 50%;
  background-repeat: no-repeat;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-size: 15px;
  color: var(--GRAY_DARK_SHADOW_BACKGROUND);

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.main-container--here {
  margin: 50px;
}
.search-filter {
  width: 100%;
  height: 37px;
}
