.otp-Container {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 40px 0;
}

.code {
  border-radius: 5px;
  border: 1px solid var(--GRAY_DARK_SHADOW_BACKGROUND);
  font-size: 25px;
  width: 40px;
  height: 45px;
  text-align: center;
  font-weight: 300;
  margin: 5px;
}
.code::-webkit-outer-spin-button,
.code::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.code:focus-visible {
  outline-color: var(--DARK_BLUE);
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.13);
}

.otp_Timer {
  color: var(--DARK_BLUE);
  text-align: center;
}
