button.accordion-button {
  background-color: var(--WHITE_FADE);
  border: 1px solid #625757;
  color: var(--Gray);
  font-size: 1.5rem;
}

button.accordion-button:focus {
  background-color: var(--BLUE_LIGHT);
  box-shadow: none;
  color: var(--WHITE);
}
