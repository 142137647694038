.columnStyle {
  padding: 10px;
  border: 1px solid red;
  margin: 5px;
  border: none;
  display: flex;
  justify-content: space-between;
}
.cc {
  border: 1px solid #fff;
  height: 3rem;
}
.title-head {
  padding: 3rem;
}
.table-title {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.markUp-table {
  width: 60%;
  /* padding: 0 !important; */
}
.markUp-table td {
  padding: 0;
  text-align: left;
  padding-left: 1rem;
}
.Markup-btn {
  width: 40%;
  margin: 5px;
  background-color: var(--BLUE_LIGHT);
}
