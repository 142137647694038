/* Vertical line styling */
.vertical-line {
    width: 2px; /* Line thickness */
    background-color: gray; /* Gray color for the line */
    height: auto; /* Adjust based on content */
    align-self: stretch; /* Ensure it spans the height of the container */
  }
  
  /* Parent container for alignment */
  .d-flex {
    align-items: center; /* Center align the items vertically */
  }
  
  /* Text sections alignment */
  .text-section {
    text-align: left; 
    border-right: 1px solid #D0D6DE;
  }
  
  /* Adjust spacing and font styles for sections */
  .text-section h6 {
    font-size: 28px;
    margin: 5px 0 0;
    line-height: 34px;
    color: #464255;
    font-weight: 600;
  }
  
  .text-section p {
    font-size: 18px;
    margin: 0;
    color: #464255;
  }
  


  .text-section {
    width: 30%;
    padding: 0 0px;
    margin-right: 3%;
}

.StatsSection {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 30px;
}

.text-section:last-child {
    border: none;
}


@media screen and (max-width: 1699px) {

.text-section h6 {
  font-size: 22px;
  margin: 3px 0 0;
  line-height: 28px;
}
}
