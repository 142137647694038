.inputBox {
  position: relative;
}
.input {
  height: 45px;
  outline: none;
}

.inputBox label {
  position: absolute;
  bottom: 40px;
  left: 12px;
  text-align: left;
  display: inline-block;
  height: 16px;
  font-size: 16px;
  font-weight: 600;
  background: #fff;
  color: var(--DARK_BLUE);
}

.error {
  color: var(--RED);
  margin-top: -20px;
  padding-bottom: 20px;
}

.inputIcons {
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
}
/* .inputBox input:hover,
.inputBox input:focus {
  border: 1px solid var(--BLUE);
}

.inputBox input:valid ~ label,
.inputBox input:focus ~ label {
  top: -6px;
  color: #000;
  font-weight: bold;
} */
/* .gl-form-asterisk {
  background-color: inherit;
  color: var(--RED);
  padding: 0;
  padding-left: 3px;
}
.gl-form-asterisk:after {
  content: "*";
} */
