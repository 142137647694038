/* .div3Sections {
  display: grid;

  grid-template-columns: 41.3% 15.3% 36.3%;
  gap: 21px;
  padding-bottom: 20px;
} */
.vr {
  margin-left: 20px;
}

.tbl-tr tr th {
  padding: 10px 0 !important;
}
.current-Box {
  border-radius: 4px;
  border: 1px solid rgba(130, 123, 123, 0.25);
}

.twoboxeswithLine {
  display: grid;
  grid-template-columns: 47% 5px 47%;
  gap: 20px;
  padding: 10px 0;
}
