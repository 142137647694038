.cardBox {
  display: inline-grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  margin: 20px 0px 20px 23px;
}
.card {
  min-height: 100px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid var(--GRAY_DARK_SHADOW_BACKGROUND);
  background: #fff;
}
.card:hover {
  box-shadow: 0 0 10px var(--GRAY_DARK_SHADOW_BACKGROUND);
  transition: box-shadow 0.3s ease-in-out;
  border: 1px solid var(--BLUE);
}
