/* Ensure the modal header's background, border-radius, and text alignment */
.rs-modal-header.custom-header {
  background: #f5f5f5 !important;
  width: 100%;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

/* Style the modal title */
.rs-modal-title.custom-title {
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: #000 !important;
  padding: 10px;
  background: #F5F5F5 !important;
  border-top-left-radius: 8px !important;
  
}

/* Style the modal close button */

.rs-modal-header .rs-btn-close .rs-icon {
  color: #fff !important;
}
.rs-modal-header .rs-btn-close {
  background-color: #b65433 !important;
  border-radius: 50% !important;
  width: 20px !important;
  height: 20px !important;
}
/* Style the close icon inside the button */

.rs-modal-body{
  margin-top: 0px !important;
}