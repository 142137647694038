@media (max-width: 991px) {
  .navbar-collapse {
    background-color: #f1eaea;
  }
  .navbar-nav {
    flex-direction: unset !important;
    display: flex;
    justify-content: end;
    margin-right: 20px;
  }
  /* .nav-item {
    margin-right: 20px;
  } */
}
@media (max-width: 768px) {
  .nav.nav-tabs.custom {
    flex-wrap: wrap;

    justify-content: flex-start;
  }
  .nav.nav-tabs.custom li {
    margin-right: 10px;
    margin-bottom: 5px;
    width: calc(50% - 10px);
  }
}
@media (max-width: 425px) {
  .start_onboarding {
    margin-left: 120px;
  }
}

@media (max-width: 425px) {
  .button-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .responsiver-button {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 745px) {
  .downbtn {
    padding-top: 10px;
  }
}
@media (max-width: 969px) {
  .topadding {
    padding-top: 10px;
  }
}
@media (max-width: 768px) {
  .repoartmonth {
    padding-top: 10px;
  }
  .repoartmonth {
    padding-bottom: 10px;
  }
  .rs-picker-toggle-wrapper {
    width: 289px;
  }
  .button-set {
    width: 219%;
  }
  .salary {
    margin-top: 30px !important;
  }
}
@media (max-width: 1154px) {
  .cardpadding {
    padding-top: 10px;
  }
  .button-set {
    width: 100%;
  }
}
@media (max-width: 424px) {
  .cardpadding {
    padding-top: 10px;
  }
  .repoartmonth {
    padding-bottom: 10px;
  }
}
@media (max-width: 978px) {
  .seacrch {
    padding-top: 10px;
  }
}
@media (max-width: 574px) {
  .CircularProgressbar {
    width: 50% !important;
  }
}
@media (max-width: 1440px) {
  .addholiday {
    text-align: end;
  }
  .rs-picker-toggle-wrapper {
    width: 289px;
  }

  .m-b-14 {
    margin-top: 16px;
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .col-xxl-4 {
    width: 50% !important;
  }
  .m-b-14 {
    margin-top: 16px;
  }
}
@media (min-width: 1196px) and (max-width: 1440px) {
  .responsive14 {
    height: 570px !important;
  }
}
@media (max-width: 990px) {
  .responsm {
    margin-top: 10px !important;
  }
}
@media (max-width: 765px) {
  .mont {
    margin-top: 10px !important;
  }
  .mon-14 {
    margin-top: 10px !important;
  }
}
