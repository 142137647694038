@import "rsuite/dist/rsuite.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input,
select,
textarea {
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}
html {
  height: 100%;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--white);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.card_Col {
  height: 100%;
  width: 100%;
  /* margin: 30px 0 20px 0;
  padding: 20px; */
}



.error_message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--RED);
}
.formDesign {
  border-radius: 20px;
  background: var(--WHITE);
  box-shadow: 0px 7px 5px 6px var(--GRAY_DARK_SHADOW_BACKGROUND);
}
.experience_form {
  width: 600px;
  margin-top: 20px;
  border: 1px solid var(--BLACK);
  padding: 20px;
  border-radius: 5px;
  background-color: var(--WHITE_OFF);
  margin: 20px 0px 0px 300px;
  color: var(--WHITE);
}

h2 {
  text-align: center;
  /* padding: 30px 30px; */
}
.navbar.header {
  background-color: #fff;
  padding: 10px 20px;
}


.navbar.header .logo {
  width: 250px;
  text-align: center;
}
.navbar.header .logo a {
  display: inline-block;
}

.navbar.header .logo a img {
  height: 60px;
}


.header_Searchbar {
  margin: 10px 0px 9.2px 0px;
}
.header_input {
  background-color: var(--WHITE_FADE);
  border: 1px solid var(--WHITE_OFF);
  width: 289px;
  height: 37.6px;
  flex-shrink: 0;
  outline: none;
}

.header_input::placeholder {
  color: var(--WHITE_OFF);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 6px 192px 15px 19px;
  border: 1px solid unset;
}
.header_input:hover {
  border: 1px solid var(--BLUE);
  box-shadow: 0px 0px 4px 2px var(--BLUE_LIGHT);
}

.button {
  border-radius: 4px;
  background: #18BDC3;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 6px 12px;
}

.button:hover {
  background: #F26532;
}
.header_icons {
  width: 130px;
  flex-shrink: 0;
  margin: 18px 50px 17.8px;
}
.icons {
  font-size: 24px;
  color: var(--GRAY_DARK_SHADOW_BACKGROUND);
}
.icons:hover {
  color: var(--BLUE_LIGHT);
}


.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-link:focus,
.nav-link:hover {
  color: var(--bs-nav-link-hover-color);
}
a:hover,
a:active {
  text-decoration: none;
}
.active {
  text-decoration: none !important;
}
/* table */

.table_wrapper {
  width: 100%;
}
table {
  border-radius: 10px !important;
}
thead th:first-child {
  border-top-left-radius: 0px !important;
}

thead th:last-child {
  border-top-right-radius: 0px !important;
}
tbody tr td:first-child {
  border-bottom-left-radius: 0px !important;
}

tbody tr td:last-child {
  border-bottom-right-radius: 0px !important;
}

.main_table {
  overflow: hidden;
  table-layout: auto;
  /* border-collapse: collapse; */
  border-radius: 10px;
   width: 100%;
  max-width: 100%; 
  white-space: nowrap;
  margin: auto;
  overflow-x: auto;
  overflow-y: auto;
}


.main_table th {
  font-size: 16px;
  color: #B5B7C0;
  font-weight: 500;
  background: #fff;
  padding: 11px 10px;
  border-bottom: 1px solid #EEEEEE;
}

.main_table td {
  font-size: 15px;
  color: #292D32;
  font-weight: 400;
  background: #fff;
  padding: 11px 10px;
  border-bottom: 1px solid #EEEEEE;
}

tbody, tfoot, thead {


  width: 100%;
}


/* .main_table tbody tr:hover {
  background-color: var(--WHITE_FADE);
} */
.table_Select {
  border: none;
  background-color: var(--WHITE_FADE);
  font-weight: bold;
  outline: none;
}
/* .nav-link{
  color:#625757;
} */

/* Tab  */

/* nav.li.tab-button:active{
  border-color: #376BEE;
} */



.tab-header {
  background: #fff;
  padding: 10px 200px 10px 10px;
  border-radius: 4px;
}

.tab-header ul.nav.nav-tabs {
  border: none !important;
}

.tab-header ul.nav.nav-tabs li.nav-item {
  margin-right: 10px;
}

.tab-header ul.nav.nav-tabs li.nav-item .nav-link.active {
  border: none;
  background: #f26532;
  border-radius: 4px !important;
  color: #fff;
}

.tab-header ul.nav.nav-tabs li.nav-item .nav-link {
  color: #827B7B;
  border: none !important;
}


.tab-container {
  position: relative;
  margin: 15px 0 0;
}

.start_onboarding {
  position: absolute;
  right: 10px;
  top: 12px;
}


.tab-container .tab-content {
  margin: 20px 0 0;
}



.Onboarding-title {
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  text-transform: capitalize;
  padding: 10px 0 10px;
}

.Onboarding-info-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0px;
  position: relative;
}

.Onboarding-info-row:before {
  content: '';
  position: absolute;
  top: 20px;
  background: #D2D1D1;
  height: 3px;
  left: 12.5%;
  right: 12.5%;
  z-index: 2;
}

.Onboarding-check {
  flex: 0 0 auto;
  width: 25%;
  text-align: center;
  position: relative;
}

.Onboarding-check svg {
  color: #D2D1D1;
  width: 40px;
  height: 40px;
  position: relative;
  z-index: 11;
  background: #fff;
}

.Onboarding-check-title {
  color: #6A6666;
  font-size: 16px;
  line-height: 26px;
  padding: 10px 0 0;
  font-weight: 500;
}

.Onboarding-check:before {
  content: '';
  position: absolute;
  top: 20px;
  background: #fff;
  height: 3px;
  left: 50%;
  right: -50%;
  z-index: 1;
}

.Onboarding-check.active svg{
  color: #18BDC3;
}

.Onboarding-check.active .Onboarding-check-title {
  color: #18BDC3;
}


.Onboarding-check.active:before {
  background: #18BDC3;
  z-index: 9;
}


.Onboarding-check:last-child:before {
 display: none;
}


.details-user-img {
  float: left;
  width: 250px;
}

.details-top-right {
  float: right;
  width: calc(100% - 250px);
  padding: 10px 0 0 10px;
}

.details-user-img figure {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #827b7b;
  margin: 0 0 0px;
}

.details-user-img figure img.userImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.top-details {
  padding: 10px 0 20px;
}

label.lables {
  font-size: 16px;
  color: #353535;
  font-weight: 500;
  margin: 0 0 8px;
}

.form-control {
  border: 1px solid #CACACA !important;
}

.form-control:focus {
  border-color: #CACACA !important;
  box-shadow: 0px 4.15px 4.15px 0px #0000000A !important;
}

.form-group {
  margin: 0 0 15px;
}

.details-user-img button.button.undefined {
  background: #f4d6cc;
  color: #f26532;
  border: 1px solid;
  font-size: 14px;
  padding: 4px 10px;
}

.details-top-right .form-group {
  margin: 10px 0 15px;
}

button.button.undefined.btn-Continue {
  color: #f26532;
  background: #fff;
  border: 1px solid;
}




.form-control .downloadAttachmentform {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.downloadAttachmentform button.button {
  padding: 0;
  background: none !important;
  color: #f26532;
}

span.material-symbols-outlined.pointer.button {
  background: none;
  padding: 0;
  color: #2d9cdb;
  font-size: 22px;
}


/* Ensure equal heights for all cards */





.formgroup-check {
  padding: 5px 0 10px;
}

.formgroup-check label.lables {
  margin: 0 10px;
  font-weight: 400;
}


.cardbox {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 15px;
}

/* login page */
.background {
  display: flex;
  background-image: url("../public/Images/background4.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 94.3vh;
  align-items: center;
  justify-content: center;
}

.content_login {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  border-radius: 10px;
  fill: var(--WHITE);
  filter: drop-shadow(0px 4px 4px var(--GRAY_DARK_SHADOW_BACKGROUND));
}

.right_col {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid #e2e1e1;
  height: 80vh;
  width: 90%;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.groupboxtop {
  position: absolute;
  top: -38px;
  left: -50px;
}
.groupboxbottom {
  position: absolute;
  bottom: -38px;
  right: -50px;
}
.circular-bar-height {
  height: 200px;
  width: 200px;
}
.divSections {
  display: grid;
  grid-template-columns: 33.3% auto 33.3%;
  gap: 20px;
}

.subheaderbackground {
  background: var(--WHITE_FADE);
}

.subheaderbackgroundoffWhite {
  background: var(--WHITE_OFF);
}



.userImageBlock {
  width: 220px; 
 height: 220px; 
  text-align: center;
}


.div2Sections {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 30px;
  align-items: center;
}

.div2Sections .userImageBlock img.userImage {
  height: 100% !important;
  max-width: 100%;
  width: 100% !important;
  object-fit: cover;
  border-radius: 8px;
}
.div2Sections .userImageBlock{
  float: left;
}

.candidatepre-right {
  float: right;
  width: calc(100% - 220px);
  padding-left: 5%;
}

ul.candidatepreviewform {margin: 0;padding: 0 20px;display: flex;flex-wrap: wrap;justify-content: space-between;    max-width: 890px;}

ul.candidatepreviewform li {
    flex: 0 0 auto;
    width: 49%;
    list-style: none;
    font-size: 15px;
    margin: 0 0 10px;
}

ul.candidatepreviewform li strong {
    width: 150px;
    display: inline-block;
}

.card-head-right.gap-10 {
  gap: 10px !important;
}

.boxshadow {
  box-shadow: var(--BOXSHADOW);
}

.borderradius {
  border-radius: 20px;
}

.astricRequired {
  color: var(--RED);
}

.nowrap {
  white-space: nowrap;
}


.Header {
  position: sticky;
  top: 0;
}

.twoboxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 10px 30px;
}

.relative {
  position: relative;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  height: 18px;
  width: 18px;
}

.bordermodal {
  border: 1px solid var(--BLACK);
  border-radius: 6px;
  padding: 20px 40px;
}

.download-icon,
.download-icon:hover,
.download-icon:active {
  background-color: var(--BLUE_LIGHT);
  color: var(--WHITE);
  display: flex;
  align-items: center;
  gap: 0px;
  padding: 2px 10px 0 0;
  cursor: pointer;
  text-decoration: none;
  justify-content: center;
  max-width: 300px;
}

.cardBoxes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 20px;
}

.capitalize {
  text-transform: capitalize;
}

.normalBold {
  font-weight: 500;
}

.normalCard {
  min-height: 100px;
  border-radius: 6px;
  border: 1px solid var(--GRAY_DARK_SHADOW_BACKGROUND);
  background: #fff;
  padding: 20px;
  margin: 20px;
}

.normalCard:hover {
  box-shadow: 0 0 10px var(--GRAY_DARK_SHADOW_BACKGROUND);
  transition: box-shadow 0.3s ease-in-out;
  border: 1px solid var(--BLUE);
}

.w-65 {
  width: 65% !important;
}

.w-10 {
  width: 10% !important;
}

/* .fixed-alert {
  position: fixed !important;
  top: 30px;
  width: 40%;
  right: 20px;
  z-index: 9;
  background: red !important;
} */
.chartboxshadow {
  box-shadow: 0px 3px 6px 1px var(--WHITE_OFF);
}



.row.Dashmiddle-row {
  padding: 22px 0;
}

.rs-calendar-header {
  border-bottom: 1px solid #C8C8CA;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.button.undefined {
  background: #f26532;
}

.button.undefined:hover {
  background: #18bdc3;
}
.rs-calendar-body {
  padding: 0px 0px 0px !important;
}

.rs-calendar-bordered .rs-calendar-table {
  border: none !important;
}

.rs-calendar-table-cell {
  border-bottom: none !important;
}

.rs-calendar-table-header-cell {
  border-bottom: none !important;
}

.rs-calendar-table-header-cell-content {
  color: #CFCFCF!important;
  display: inline-block;
  font-size: 16px !important;
  padding-bottom: 25px !important;
  padding-top: 15px !important;
}

.rs-calendar {
  padding-top: 0px !important;
}

.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
  background-color: #F26532 !important;
  color: #fff !important;
  height: 100% !important;
  margin-left: auto;
  margin-right: auto;
  width: auto  !important;
  display: block !important;
  margin: 0 !important;
  border-radius: 4px !important;
}

.rs-calendar-panel .rs-calendar-table-cell-day {
  margin-top: 0px !important;
  height: 100% !important;
  line-height: 38px !important;
  border-radius: 4px  !important;
  position: relative;
}


.rs-calendar-table-cell-content:hover {
  background-color: transparent !important;
}

.rs-calendar-panel .rs-calendar-table-cell-day:hover {
  background-color: #F26532 !important;
  color: #fff !important;
}


.rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content .rs-calendar-table-cell-day {
  background-color: #F26532 !important;
  color: #fff !important;
}

.rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
  box-shadow: none !important;
}
.rs-calendar-table-cell-content {
  width: 100% !important;
  height: 50px !important;
  overflow: unset !important;
}



.rs-calendar-panel .rs-calendar-table-cell-day::before {
  content: '';
  position: absolute !important;
  width: 4px !important;
  height: 4px !important;
  z-index: 1;
  border-radius: 1px !important;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  top: auto !important;
  bottom: 5px !important;
  background: #18BDC3;
}
.rs-calendar-panel .rs-calendar-table-cell {
  line-height: 0;
  padding: 4px 5px !important;
}

.rs-calendar-month-dropdown-row {
  padding: 5px 10px 5px 70px !important;
  height: auto !important;
}

.rs-calendar-panel .rs-calendar-month-dropdown {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 0px !important;
  top: 50px !important;
  width: 100% !important;
}

.rs-calendar-month-dropdown-year-active {
  color: #F26532 !important;
}

.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  background-color: #F26532 !important;
}

.rs-calendar-month-dropdown-cell-content {
  font-size: 12px !important;
  line-height: 18px !important;
  padding: 2px 10px !important;
}

.rs-calendar-panel .rs-calendar-table-cell.rs-calendar-table-cell-is-today .rs-calendar-table-cell-day::before{
  background: #fff !important;
}

.rs-calendar-table-cell.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-day::before{
  display:  none !important;
}

.rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content .rs-calendar-table-cell-day::before {
  background: #fff !important;
}


.rs-btn-sm {
  font-size: 20px !important;
  color: #464255 !important;
  font-weight: 600 !important;
  background: #fff !important;
}
.generate-letter-container {
  position: relative;
}

.alert-container {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000; /* High z-index to ensure it appears above other elements */
}
.rs-picker-toggle-label {
  font-weight: 600;
}

.currency-symbol {
  position: absolute;
  left: 1.3rem;
  top: 32%;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #333;
}

.search-filter-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.search-filter-wrapper .form-control {
  padding-left: 1rem;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 3rem;
  appearance: none;
  background: #F3F2F7 !important;
  box-shadow: none !important;
  border-color: #EBEBEB !important;
}

.search-filter-wrapper .search-icon {
  position: absolute;
  right: 15px;
  color: #6c757d;
  pointer-events: none;
  width: 25px;
  height: 25px;
}

.search-filter-wrapper .clear-icon {
  position: absolute;
  right: 10px;
  color: #6c757d; /* Adjust the color as needed */
  cursor: pointer;
}

/* Remove the default clear (x) icon on hover in all browsers */
.search-filter-wrapper .form-control::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

.search-filter-wrapper .form-control::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.search-filter-wrapper .form-control::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.search-filter-wrapper .form-control::-webkit-search-decoration {
  display: none;
}
.text-wrap-break {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.sidebarlogo-image {
  max-width: 125px;
  min-width: 75px;
  width: 100%;
  height: auto;
}



iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.responsive-table {
  overflow: auto;
}



.AttendanceFilters {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: end;
}

.AttendanceFilters .form-select {
  width: 200px;
}



.SubHeaderrepoartmonth {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 15px;
  margin: 0 0 20px;
}

.SubHeaderrepoartmonth .repoartmonth {
  max-width: 200px;
}








@media screen and (max-width: 1699px) {

.rs-calendar-table-cell-content {
  height: 45px !important;
  padding: 0 !important;
}
.rs-calendar-table-header-cell-content {
  font-size: 16px !important;
  padding-bottom: 8px !important;
  padding-top: 15px !important;
}

.rs-calendar-panel .rs-calendar-table-cell {
  line-height: 0;
  padding: 3px 3px !important;
}
.rs-btn-sm {
  font-size: 18px !important;
  color: #464255 !important;
  font-weight: 600 !important;
  background: #fff !important;
}
.main_table td {
  font-size: 14px;
  color: #292D32;
  font-weight: 400;
  background: #fff;
  padding: 6px 10px;
  border-bottom: 1px solid #EEEEEE;
}

.main_table th {
  font-size: 16px;
  color: #B5B7C0;
  font-weight: 500;
  background: #fff;
  padding: 7px 10px;
  border-bottom: 1px solid #EEEEEE;
}






}




@media screen and (max-width: 1400px) {


.navbar.header .logo {
  width: 180px;
}

.navbar.header .logo a img {
  height: 50px;
}


.AttendanceFilters .form-select {
  width: 120px;
}


.button {
  font-size: 14px;
  line-height: 24px;
  padding: 4px 12px;
}

.search-filter-wrapper .form-control {
  padding-left: 11px;
  height: 35px;
  font-size: 14px;
}


.search-filter-wrapper .search-icon {
  right: 10px;
  width: 20px;
  height: 20px;
}






}