@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  position: relative;
  min-height: 100%;
  padding-bottom: 5rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F5F5F5 !important;
  font-family: Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.head-title-bg {
  padding: 20px 0px 0;
}

.page-title {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  font-family: Poppins, sans-serif;
}


.row.topcard-row {
  padding: 22px 0;
}

.topcard {
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  height: 100%;
}

.topcard figure {
  margin: 0;
  float: left;
  width: 80px;
  height: 80px;
  line-height: 80px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
}
.topcard figure figure img{
  max-width: 100%;
  height: auto;
  max-height: 50px;
}
.topcard-info {
  float: right;
  width: calc(100% - 80px);
  padding-left: 15px;
}

.topcard-info h4 {
  color: #fff;
  margin: 0 0 5px;
}

.topcard-info p {
  color: #fff;
  margin: 0 0px;
}



.navbarmenu ul {
    padding: 0;
    margin: 0;
    display: flex;
        align-items: center;
        gap: 25px;
}

li.NotificationsOutline {
    list-style: none;
}

li.NotificationsOutline a {
    color: #f26532;
    width: 50px;
    height: 50px;
    border-radius: 18px;
    background: #f4d6cc;
    display: inline-block;
    position: relative;
    font-size: 28px;
    text-align: center;
    line-height: 50px;
}

li.NotificationsOutline a span {
    border: 2px solid #F3F2F7;
    background: #f26532;
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-block;
    font-size: 10px;
    position: absolute;
    right: -8px;
    top: -8px;
    line-height: 21px;
}


li.MessageSquare {
  list-style: none;
}

li.MessageSquare a {
  color: #2D9CDB;
  width: 50px;
  height: 50px;
  border-radius: 18px;
  background: #b9daed;
  display: inline-block;
  position: relative;
  font-size: 28px;
  text-align: center;
  line-height: 50px;
}

li.MessageSquare a span {
  border: 2px solid #F3F2F7;
  background: #2D9CDB;
  color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  font-size: 10px;
  position: absolute;
  right: -8px;
  top: -8px;
  line-height: 21px;
}



.navbarmenu {
  padding-right: 30px;
  margin-right: 20px;
  border-right: 2px solid #D0D6DE;
}


.profile-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-head {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.profile-name {
  font-size: 16px;
  text-transform: capitalize;
}

.profile-name span {
  font-weight: 600;
}




.sideBarmenu-icon {
    text-align: right;
    color: #fff;
    font-size: 22px;
    padding: 0 15px;
}
.sideBarmenu-icon svg{
  cursor: pointer;
}

 svg.m-menu {
    display: none;
}


.sideBarw.sideBar-close .sideBarmenu-icon svg.m-menu {
    display: block !important;
}

.sideBarw.sideBar-close .sideBarmenu-icon svg.d-menu {
    display: none !important;
}


.card {
  box-shadow: 0px 4.15px 4.15px 0px #0000000A !important;
  border: none !important;
  padding: 15px !important;
}

.row.Dashtop-row .card {
  height: 100%;
}



.row.Dashtop-row .col-2 {
  width: 30%;
}

.row.Dashtop-row .col-4 {
  width: 34%;
}

.row.Dashtop-row .col-5 {
  width: 36%;
}

.card-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0 !important;
  color: #464255 !important;
}
.card-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0 10px;
}


.selectbtn select {
  border: none !important;
  width: auto;
  padding: 2px 27px 2px 7px;
  color: #625757;
  -webkit-appearance: none !important;
  background: url(../src/assets/images/selectarrow.png) no-repeat !important;
  background-position: right 8px center !important;
  font-size: 14px;
  background-size: 12px !important;
}


.selectbtn {
  border: 1px solid #2D9CDB;
  border-radius: 4px;
  padding: 2px 8px;
  color: #625757;
  font-size: 14px;
}




.Birthday-day h5 {
    color: #464255;
    font-size: 14px;
    margin: 10px 0 0px;
    font-weight: 600;
}

.Birthday-day ul {
    margin: 0;
    padding: 0;
}

.Birthday-day ul li {
    list-style: none;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 17px 0;
    border-bottom: solid 1px #E6E6E6;
}

.Birthday-text {
    display: flex;
    align-items: center;
}

.Birthday-text figure {
    margin: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    float: left;
}

.Birthday-text figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Birthday-name {
    float: right;
    width: calc(100% - 60px);
    padding-left: 16px;
}

.Birthday-name h6 {
    color: #464255;
    font-weight: 600;
    margin: 0px 0 2px;
    font-size: 16px !important;
}

.Birthday-name span {
    font-size: 13px;
    line-height: 18px;
    color: #464255;
}

.Birthday-date {
    display: flex;
    align-items: center;
    gap: 10px;
}

.Birthday-date figure {
    margin: 0;
    background: #f4d6cc;
    color: #f26532;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
}

.Birthday-date p {
    margin: 0;
    color: #464255;
    font-weight: 500;
    font-size: 14px;
}


.Birthday-info.Anniversary .Anniversarytext {
    color: #49CBD0;
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 5px;
}



.Birthday-info.Anniversary .Birthday-date figure {
    background: #b9daed;
    color: #2D9CDB;
}

.row.Dashmiddle-row .col-8 {
  width: 70%;
}

.row.Dashmiddle-row .col-4 {
  width: 30%;
}


.main_table tr th:last-child, .main_table tr td:last-child {
  text-align: center;
}

svg.Viewbtn {
  width: 22px;
  height: 22px;
  color: #f26532;
}

svg.Viewbtn:hover{
  color: #18bdc3;
}

svg.Downloadbtn {
  width: 18px;
  height: 20px;
  color: #2D9CDB;
}

svg.Downloadbtn:hover{
  color: #18bdc3;
}

.Actiondata svg.Downloadbtn {
  position: relative;
  margin-left: 10px;
  border-left: 2px solid #ccc;
  width: 30px;
  padding-left: 10px;
}


 span.blue {
  padding: 5px 5px;
  border: 1px solid #2D9CDB;
  color: #2D9CDB;
  border-radius: 4px;
  font-size: 12px;
  background: #b9daed;
}

 span.red {
  padding: 5px 5px;
  border: 1px solid #f26532;
  color: #f26532;
  border-radius: 4px;
  font-size: 12px;
  background: #f4d6cc;
}

span.green {
  padding: 5px 5px;
  border: 1px solid #008000;
  color: #008000;
  border-radius: 4px;
  font-size: 12px;
  background: #00800033;
}

 span.info {
  padding: 5px 5px;
  border: 1px solid #B5B7C0;
  color: #B5B7C0;
  border-radius: 4px;
  font-size: 12px;
  background: #b5b7c03d;
}





span.rejectedClass {
  padding: 5px 5px;
  border: 1px solid #f26532;
  color: #f26532;
  border-radius: 4px;
  font-size: 12px;
  background: #f4d6cc;
  min-width: 80px;
  text-align: center;
  display: inline-block;
}

span.actionsINFO {
  padding: 5px 5px;
  border: 1px solid #2D9CDB;
  color: #2D9CDB;
  border-radius: 4px;
  font-size: 12px;
  background: #b9daed;
  min-width: 80px;
  text-align: center;
  display: inline-block;
}

span.actionsPending {
  padding: 5px 5px;
  border: 1px solid #FDB731;
  color: #FDB731;
  border-radius: 4px;
  font-size: 12px;
  background: #fff3dc;
  min-width: 80px;
  text-align: center;
  display: inline-block;
}



span.approvedClass {
  padding: 5px 5px;
  border: 1px solid #B5B7C0;
  color: #B5B7C0;
  border-radius: 4px;
  font-size: 12px;
  background: #edeef0;
  min-width: 80px;
  text-align: center;
  display: inline-block;
}


button.button.btn-Eye {
  border: 1px solid #8BDBDE;
  background: #DDF5F6;
  color: #18bdc3;
  font-size: 20px;
  padding: 5px 10px;
}
button.button.btn-Trash{
  background: #f4d6cc;
  border: 1px solid #f26532;
  color: #f26532;
  font-size: 18px;
  padding: 5px 10px;
}





.closeDocuments {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.position-relative.pr4 {
  padding-right: 40px;
}

.rs-pagination-group.rs-pagination-group-sm {
  padding: 14px 0 0;
}

.rs-pagination-btn.rs-pagination-btn-active {
  color: #F26532 !important;
  border-color: #F26532 !important;
  box-shadow:  none !important;
}



.Asset-info-card {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.Assetinfo {
  width: 33.33%;
  text-align: center;
}

.Assetinfo p {
  color: #464255;
  font-size: 16px;
  margin: 0 0 5px;
}

.Assetinfo h5 {
  font-size: 30px;
  line-height: 40px;
  margin: 0;
  font-weight: 600;
}

.Assetinfo.Total-A h5{
 color: #18BDC3;
}

.Assetinfo.Assigned-A h5{
  color:#F26532 ;
}

.Assetinfo.Unassigned-A h5{
  color: #FDB731;
}

.sub-title {
  color: #30991F;
  font-size: 16px;
  font-weight: 500;
  margin: 5px 0 0;
}

.row.Dashbottom-row .card {
  height: 100%;
  max-height: 500px; /* Limit max height */
  overflow-y: auto; /* Enable vertical scrolling */
}







.Shortbytbtn select {
  border: none !important;
  padding: 0px 30px 0px 5px;
  color: #625757;
  -webkit-appearance: none !important;
  background: url(../src/assets/images/selectarrow2.png) no-repeat !important;
  background-position: right 8px center !important;
  font-size: 14px;
  background-size: 12px !important;
  width: 100%;
}

.Shortbytbtn .form-control.form-select {
  box-shadow: none !important;
}



.Shortbytbtn span {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #625757;
}

.Shortbytbtn {
  border-radius: 8px;
  padding: 11px 15px;
  color: #F26532;
  font-size: 14px;
  background: #F9FBFF;
  position: relative;
  padding-left: 85px;
  text-align: left;
}


.card-head-right {
  display: flex;
  gap: 20px;
  align-items: center;
}

.card-head-right .search-filter-wrapper {
  width: 450px;
}


.row.EmployeeCardRow .col-2 {
    width: 20%;
    padding: 15px;
}

img.EmployeeImageRound {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin: 0 auto 15px;
    object-fit: cover;
    border: 1px solid #ccc;
}

h5.EmployeeTitle {
    color: #F26532;
    text-align: center;
}

p.EmployeeTitlesub {
    color: #414141;
    text-align: center;
    margin: 0;
}

.card.EmployeeCard {
    padding: 20px 10px !important;
    height: 100%;
}



.filter-select-btn {
  display: flex;
  align-items: center;
  gap: 20px;
}

.filter-select-btn select{
  width: 140px;
}


.Emp-profile-img {
  width: 120px;
  border-radius: 8px;
  float: left;
  overflow: hidden;
}

.Emp-profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Emp-profile-text {
  width: calc(100% - 120px);
  float: right;
  padding-left: 20px;
  padding-top: 5px;
}

.Emp-profile-text h5 {
  color: #16151C;
  font-size: 24px;
  margin: 0 0 12px
}

.Emp-profile-text p {
  color: #16151C;
  font-size: 18px;
  margin: 0 0 10px;
}

.Emp-profile-text p svg {
  color: #F26532;
  font-size: 24px;
  font-weight: 700;
}


.Emp-Det-List {
  padding: 15px 0 0;
}

.Emp-Det-List ul {
  padding: 0;
  margin: 0;
}

.Emp-Det-List ul li {
  list-style: none;
  padding: 0 0 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Emp-Det-List ul li p {
  margin: 0;
  color: #16151C;
}

.Emp-Det-List ul li span {
  color: #625757;
  font-weight: 300;
}




.Emp-Det-left .accordion-item {
  margin: 0 0 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4.15px 4.15px 0px #0000000A !important;
  border: none;
}

.Emp-Det-left button.accordion-button.collapsed{
  color: #464255 !important;
  box-shadow:  none !important;
}


.Emp-Det-left button.accordion-button {
  background: #fff !important;
  border: none !important;
  font-size: 18px !important;
  font-weight: 600;
  margin: 0 !important;
  color: #F26532 !important;
  padding: 20px 20px;
  border-radius: 8px !important;
  box-shadow:  none !important;
}



.Emp-Det-left .accordion-body {
  padding: 0 20px 20px !important;
}

.form-group .Emp-form-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 250px;
}

.form-group .Emp-form-info p {
  margin: 0;
}

.form-group .Emp-form-info span {
  color: #F26532 !important;
}

.AttendanceReporttop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 0px;
}

.AttendanceReporttop .head-title-bg {
  padding: 0px 0px 0;
}

.AttendanceReporttop .search-filter-wrapper {
  width: 420px;
}


.form-group .Emp-form-info span svg {
  font-size: 20px;
}


.Attendance-table .main_table th {
  padding: 9px 10px;
}

.Attendance-table .main_table td {
  padding: 9px 10px;
}



.CustomDropdownAction {
    display: inline-block !important;
}

.Actions-data-select {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.CustomDropdownAction .rs-dropdown-toggle {
    border: 1px solid #FF0606;
    border-radius: 4px;
    background: #FF0606 !important;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 5px 32px 5px 12px;
}



.CustomDropdownAction .rs-dropdown-menu {
  width: 100% !important;
}

.CustomDropdownAction .rs-dropdown-menu .rs-dropdown-item {
  font-size: 14px !important;
}

.CustomDropdownAction .rs-dropdown-menu .rs-dropdown-item.rs-dropdown-item-focus {
  background: #f4d6cc !important;
  color: #f26532 !important;
}


.checkINOut button.button {
  padding: 12px 23px;
  border: solid 1px;
}

.checkINOut button.button.BreakTime-outline {
  color: #f26532;
  background: #fff;
}

.checkINOut button.button.BreakTime-outline:hover {
  color: #fff;
  background: #f26532;
}

.colormain {
  color: #f47c51;
}

.project-card {
  border: 1px solid #E0E0E0;
  padding: 20px;
  border-radius: 4px;
  margin: 10px 0 0;
}

.project-card h6 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 15px;
}


.Management-info {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
}

.Management-info figure {
  width: 55px;
  height: 55px;
  margin: 0;
  border-radius: 50%;
  overflow: hidden;
  float: left;
  line-height: 53px;
  text-align: center;
}

figure.i-bg {
  background: #f4d6cc;
  color: #f26532;
  font-size: 24px;
}


.Management-info figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Management-title {
  width: calc(100% - 55px);
  float: right;
  padding-left: 10px;
  font-weight: 300;
  font-size: 16px;
}

.Management-title strong {
  display: block;
  font-weight: 500;
}



.ProjectsTasks {
  background: #f9f2f2;
  border: 1px dashed #ccc;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.ProjectsTasks figure {
  margin: 0;
  background: #ffecc8;
  color: #FEB019;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
  font-size: 20px;
  margin-right: 10px;
}

.ProjectsTasks figure svg {
  margin-left: -2px;
}


.ProjectsTasks strong {
  padding-right: 5px;
  font-weight: 500;
}

.TimeSpent-bg {
  border-radius: 4px;
  background: #18BDC3;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 0;
}



.checkbox-LABLE input {
  width: 16px;
  height: 16px;
}

.checkbox-LABLE label.lables {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
}


.checkbox-LABLE {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 0 10px;
}




.page-title nav.rs-breadcrumb {
    margin: 0;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    font-family: Poppins, sans-serif;
    color: #F26532;
}


.page-title nav.rs-breadcrumb a.rs-breadcrumb-item {
    color: #464255;
    text-decoration: none;
}

.page-title nav.rs-breadcrumb span.rs-breadcrumb-item.rs-breadcrumb-item-active {
    color: #F26532;
}

.page-title nav.rs-breadcrumb a.rs-breadcrumb-item:hover {
    color: #f26532;
}

.head-title-row {
    display: flex;
    flex-wrap: wrap;
}





button.delete-submit-btn {
  padding: 5px 15px;
  border: 1px solid #f26532;
  color: #f26532;
  border-radius: 4px;
  font-size: 14px;
  background: #f4d6cc;
  text-align: center;
  min-width: 100px;
}

button.yes-btn {
  padding: 5px 5px;
  border: 1px solid #FDB731;
  color: #FDB731;
  border-radius: 4px;
  font-size: 14px;
  background: #fff3dc;
  min-width: 100px;
}


button.button.undefined.buttonApprove {
  background: #18bdc3;
}


.row.AssetsManagement-head {
  padding: 15px 0 0;
  align-items: center;
}

.row.AssetsManagement-head .head-title-bg {
  padding: 0px 0px 0;
}

.row.topcard-row.Assetstopcard .col-sm-3:nth-child(1) .topcard{
  background-color: rgb(24, 189, 195);
}

.row.topcard-row.Assetstopcard .col-sm-3:nth-child(2) .topcard{
  background-color: rgb(253, 183, 49);
}

.row.topcard-row.Assetstopcard .col-sm-3:nth-child(3) .topcard{
  background-color: rgb(24, 189, 195);
}

.row.topcard-row.Assetstopcard .col-sm-3:nth-child(4) .topcard{
  background-color: rgb(242, 101, 50);
}

section.salary-top-box {
  border-radius: 4px;
  border: 1px solid #ccc;
}

section.salary-top-box .salary-bg-color {
  background-color: rgb(253 183 49 / 10%);
  padding: 15px 20px 0;
}


section.salary-top-box .salary-bg-color p {
  margin: 0;
  border-bottom: 1px dashed;
  padding: 0 0 15px;
}

.markUptable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 0;
}

.Payslip-top-text {
  font-size: 16px;
}

.Payslip-top-text strong {
  display: block;
  text-align: right;
  font-size: 18px;
  line-height: 22px;
}
.PayslipEmployeeSummary p span {
  width: 200px;
  display: inline-block;
  font-weight: 500;
  color: #837c7c;
}
.PayslipUn p span {
  width: 70px;
  display: inline-block;
  font-weight: 500;
  color: #837c7c;
}
table.main_table.Salarytable th {
  width: 50%;
}

.PaidDays p{
  font-size: 16px;
}
.PaidDays p strong {
  width: 90px;
  display: inline-block;
  font-weight: 500;
  color: #000;
}

h4.totalnetsalary {
  margin: 0;
  text-align: right;
  font-size: 30px;
}
.align-flex-end{
  align-items: flex-end;
}


.Break-Attendance-box {
  align-items: center !important;
  justify-content: space-between !important;
  display: flex !important;
  margin: 56px 0;
  padding: 0 0 9px;
}


.filesize {
  font-size: small;
}











@media screen and (max-width: 1699px) {

  .topcard {
    border-radius: 8px;
    padding: 12px;
  }
  .topcard figure {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
  
  .topcard figure img {
    max-height: 30px;
  }
  .topcard-info {
    width: calc(100% - 70px);
    padding-left: 14px;
  }
  
  .topcard-info h4 {
    margin: 0 0 5px;
    font-size: 20px;
  }
  
  .topcard-info p {
    font-size: 15px;
  }
  .card-title {
    font-size: 18px;
}

.selectbtn span {
  display: none;
}

.selectbtn {
  font-size: 14px;
}

.rs-pagination-group.rs-pagination-group-sm {
  padding: 10px 0 0;
}

.Assetinfo p {
  font-size: 14px;
  margin: 0 0 5px;
}
.Assetinfo h5 {
  font-size: 24px;
  line-height: 34px;
}


.Birthday-text figure {
  width: 50px;
  height: 50px;
}
.Birthday-name {
  width: calc(100% - 50px);
  padding-left: 10px;
}


.row.EmployeeCardRow .col-2 {
  width: 25%;
  padding: 10px;
}

.card-head-right .search-filter-wrapper {
  width: 370px;
}

.AttendanceReporttop .search-filter-wrapper {
  width: 380px;
}




h2.colormain {
  font-size: 24px;
}

.Break-Attendance-box h4 {
  font-size: 20px;
}



.checkINOut button.button {
    padding: 10px 20px;
    border: solid 1px;
}
.text-section p {
  font-size: 16px;
}

.Break-Attendance-box {
  margin: 69px 0;
}

}

@media screen and (max-width: 1400px) {
.mf-dropdown span{display: none;}

.topcard {
  border-radius: 8px;
  padding: 10px;
}
.topcard figure {
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.topcard figure figure img, .topcard figure img {
  max-height: 25px;
}
.topcard-info {
  width: calc(100% - 60px);
  padding-left: 14px;
}

.topcard-info h4 {
  margin: 0 0 5px;
  font-size: 17px;
}

.topcard-info p {
  font-size: 14px;
}

.row.topcard-row {
  padding: 15px 0;
}

.row.Dashtop-row .col-4 {
  width: 100%;
  order: 3;
  margin: 30px 0 0;
}

.row.Dashtop-row .col-5 {
  width: 60%;
  order: 2;
}

.row.Dashtop-row .col-2 {
  width: 40%;
  order: 1;
}

.row.Dashmiddle-row .col-4 {
  width: 40%;
}
.row.Dashmiddle-row .col-8 {
  width: 60%;
}

.rs-pagination-sm .rs-pagination-btn {
  font-size: 14px !important;
  line-height: 18px !important;
  min-width: 25px !important; 
  padding: 3px 8px !important;
}

.profile-img {
  width: 50px;
  height: 50px;
}
.profile-head {
  gap: 10px;
}
.salary-bg-color {
  background-color: #cbecc1 !important;
}
li.MessageSquare a {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  font-size: 22px;
  line-height: 40px;
}

li.NotificationsOutline a {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  font-size: 22px;
  line-height: 40px;
}


li.MessageSquare a span {
  border: 1px solid #F3F2F7;
  width: 21px;
  height: 21px;
  font-size: 8px;
  line-height: 21px;
}

li.NotificationsOutline a span {
  border: 1px solid #F3F2F7;
  width: 21px;
  height: 21px;
  font-size: 8px;
  line-height: 21px;
}


.navbarmenu ul {
  gap: 15px;
}

.row.Dashbottom-row .col-4 {
  width: 40%;
 

}

.card.EmployeeCard {
  padding: 20px 10px !important;
  height: 100%;
}

img.EmployeeImageRound {
  width: 140px;
  height: 140px;
  margin: 0 auto 15px;
}

.Shortbytbtn span {
  display: none;
}
.Shortbytbtn {
  padding: 2px 4px;
}

.filter-select-btn {
  gap: 10px;
}

.card-head-right {
  gap: 10px;
}
.card-head-right .search-filter-wrapper {
  width: 300px;
}

.Attendance-table .main_table td {
  padding: 4px 8px;
}

h5.EmployeeTitle {
  font-size: 1rem;
}

p.EmployeeTitlesub {
  font-size: 14px;
}

.filter-select-btn select {
  width: 110px;
}
.form-select {
  padding: 2px 25px 2px 5px !important;
  height: 32px !important;
  font-size: 14px !important;
  background-position: right 0.5rem center !important;
  background-size: 14px 10px !important;
}

.page-title nav.rs-breadcrumb {
  font-size: 20px;
}


.page-title {
  font-size: 20px;
}
.tab-header ul.nav.nav-tabs li.nav-item .nav-link {
  font-size: 14px;
  padding: 5px 10px;
}


.Shortbytbtn select.form-select:focus {
  box-shadow: none !important;
}


.input_input__eWmzG {
  height: 32px !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}



}
@media screen and (max-width: 991px) {
  .topcard figure {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .topcard figure figure img{max-height: 30px;}
}