.sidebar {
  background-color: #F26532;
  z-index: 99;
  transition: all 0.5s ease;
}
.sideBar-close {
  height: 100vh;
  overflow: auto;
  width: 60px !important;
  z-index: 2;
}

.sideBarw {
  width: 270px;
  padding: 15px 0 80px;
  position: relative;
  height: calc(100vh - 80px);
}


.LogOut-menu {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #f26532;
  padding: 20px 20px 15px;
}

a.LogOut-link {
  color: #f26532;
  font-size: 16px;
  text-decoration: none;
  padding: 8px 10px;
  width: 100%;
  display: inline-block;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}

.link-item-main {
  padding: 15px 0 0;
}

li.link-item {list-style: none;padding: 0 15px;   margin: 0 0 8px;}

li.link-item a {
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  padding: 8px 10px;
  width: 100%;
  display: inline-block;
  border-radius: 4px;
}

.side-bar--icon {
  width: 70px;
  height: 50px;
  font-family: Poppins, sans-serif;
  color: var(--ORRENGE);
  text-align: center;
  border-radius: 6px;
  border: 1px solid var(--BLUE);
  box-shadow: 0px 0px 2px 2px var(--BLUE);
}
.side-bar--icon---p {
  color: var(--BLUE);
  font-size: 0.6rem;
  white-space: nowrap;
}
.side-bar--li {
  font-family: Poppins, sans-serif;
  color: var(--BLACK);
  font-size: 1rem;
  white-space: nowrap;
}
.side-bar--button a {
  display: block;
  color: var(--BLACK);
  text-decoration: none;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: 1px solid transparent;
  border-left: none;
  border-right: none;
}


li.activenavlink.link-item {
  position: relative;
}

li.activenavlink.link-item:before {
  content: '';
  position: absolute;
  top: 1px;
  width: 4px;
  background: #fff;
  left: 0;
  bottom: 0px;
  border-radius: 0px 5px 4px 0px;
}

li.link-item a svg {
  margin: -3px 0 0;
  font-size: 20px;
}

li.activenavlink.link-item a {
  background: #fff;
  color: #f26532;
}
.sub-li {
  margin-left: 1rem;
  margin-top: 5px;
}
.activeSublink {
  background: #fff;
  color: #f26532;
}


.list-subLinks {
  margin: 10px 0 0 28px;
}

.list-subLinks a {
  color: #18bdc3 !important;
  padding: 5px 0px !important;
  font-size: 14px !important;
}

.list-subLinks a.activeSublink {
  background: #fff !important;
  color: #f26532 !important;
}


.sideBar-close li.link-item a {
  padding: 8px 0px !important;
  text-align: center;
}

.sideBar-close li.link-item a svg {
  font-size: 20px;
}


.sideBar-close .sideBarmenu-icon{
  text-align: center;
  padding: 0 20px;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Header {
  flex-shrink: 0;
}

.content-container {
  display: flex;
  flex-grow: 1;
}

.main-content {
  flex-grow: 1;
  position: relative;

  min-height: 100vh;
  top: 0;

  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}


body.sidebar-closed {
  overflow: hidden;
}


ul.Dashboardmenu {
  padding: 0;
  margin: 0;
}

.sideBarw.sideBar-close .Dashboardmenu span.ps-2.dn {
  display: none;
}
.sideBarw.sideBar-close .LogOut-link span.dn {
  display: none;
}

.sideBarw.sideBar-close .LogOut-menu {
  padding: 8px 10px;
}


@media screen and (max-width: 1699px) {




}





@media screen and (max-width: 1400px) {


  .sideBarmenu-icon {
    font-size: 20px;
    padding: 0 10px;
}
li.link-item a {
  font-size: 14px;
  padding: 5px 6px;
}

li.link-item {
  padding: 0 10px;
  margin: 0 0 5px;
}

li.link-item a svg {
  margin: -3px 0 0;
  font-size: 18px;
}
.sideBarw {
  width: 240px;
  padding: 15px 0 80px;
  height: calc(100vh - 80px);
}
a.LogOut-link {
  padding: 4px 6px;
  text-align: center;
}





}




