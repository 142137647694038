.div-border {
  border: 1px solid rgba(130, 123, 123, 0.6);
  width: 180px;
  height: 43px;
  border-radius: 5px;
}
.row.topcard-row.attendancerepoart .col-sm-3:nth-child(1) .topcard {
background-color: #18BDC3;
}
.row.topcard-row.attendancerepoart .col-sm-3:nth-child(2) .topcard {
background-color: #FDB731;
}
.row.topcard-row.attendancerepoart .col-sm-3:nth-child(3) .topcard {
background-color: #18BDC3;
}
.row.topcard-row.attendancerepoart .col-sm-3:nth-child(4) .topcard {
 background-color: #F26532;
}

.inline-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #625757;
  border-radius: 5px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
}
.data-2 {
  background-color: #d9d9d9;
  padding: 13px;
  width: 52px;
  height: 41px;

  text-align: center;
  color: #625757;

  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
}
.data-1 {
  font-size: 13px;
  padding: 5px;
  color: #625757;

  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;

  /* flex-shrink: 0; */
}
