.drawer-Header {
  background-color: var(--WHITE_OFF);
}
.drawerUserImage {
  border: 2px solid #827b7b;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.drawerUserDetail {
  list-style: none;
  font-size: 18px;
}
.drawerUserDetailRight {
  list-style: none;
  font-size: 18px;
  color: var(--Gray);
}
.rs-drawer-body {
  height: calc(100% - 76px);
  overflow-y: scroll;
  padding: 30px 0px;
}
