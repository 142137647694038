.request-btn {
  border-color: var(-BLACK);
  width: 120.878px;
  height: 40px;
  flex-shrink: 0;
}

.leave-request {
  margin-top: 0;
}
.form-main {
  width: 100%;
  padding-bottom: 50px;
  flex-shrink: 0;
  border-radius: 20.111px;
  background: var(--WHITE);
  box-shadow: 0px 7px 5px 6px rgba(130, 123, 123, 0.1);
}
.request-bar {
  margin-left: 10px;
  margin-right: 10px;

  text-align: right;
  background-color: var(--WHITE_FADE);
}
.subCan {
  margin: 10px;
  padding: 10px;
}
.sub-btn {
  width: 120.878px;
  height: 35px;
  flex-shrink: 0;
  border: 1px solid #000;
  background: var(--BLUE_LIGHT) !important;
  color: var(--WHITE);
}
.can-btn {
  min-width: 120.878px;
  height: 35px;
  border: 1px solid #000;
  color: var(--WHITE);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.88px;
  background: var(--BLUE_LIGHT) !important;
}

.custom-textarea {
  padding: 0px !important;
}

.form-controlcustom {
  -webkit-appearance: menulist !important;
}
.w-65 {
  -webkit-appearance: menulist !important;
}
/* LeavePending.css */
.LeaveTableHeight {
  border-radius: 10px;
}


.LeaveTableHeight tbody tr:nth-of-type(even) {
  background-color: var(--Table_tr);
}
.custom_btn {
  border: 0.667px solid var(--BLACK);
  width: 77px;
}

.custom_button_view {
  justify-content: end;
  display: flex;
}
/* modalcss */
.leave_title {
  background: var(---BLUE_LIGHT);

  width: 100%;
  text-align: center;
  color: var(--modal-title);
  font-family: Commissioner;
  font-size: 29.911px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.542px;
}
.rs-modal-content {
  padding: 0;

  align-items: center;
  justify-content: center;
}

.testHeader {
  border-radius: 10px;
  border-radius: 10px 10px 0px 0px;
  background: var(--WHITE_FADE);
}
/* 
.yes-btn,
.delete-submit-btn,
.rs-dropdown-toggle {
  padding: 0px 5px 0px 5px;
  border: 1.522px solid black;
  background: var(--BLUE_LIGHT);
  color: var(--WHITE);
  font-family: Poppins;
  font-size: 19.485px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.339px;
} */

.rs-dropdown-item {
  padding: 0px 5px 0px 5px;
  background: var(--WHITE);
  color: var(-BLACK);
  font-family: Poppins;
  font-size: 17.485px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.339px;
}
.rs-btn:hover,
.rs-btn:focus {
  color: var(--WHITE);
  background: var(--BLUE_LIGHT);

  background: var(--BLUE_LIGHT);

  text-decoration: none;
}
.drawpop {
  width: 50%;
  display: flex;
  justify-content: center;
  margin: auto;
  background: #f9f5f5;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-bottom: 9px;
  padding-top: 9px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn,
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
  padding-right: 36px;
}
